<template>
  <a-drawer
    title="补贴详情"
    :maskClosable="false"
    width="650"
    placement="right"
    @close="handleClose()"
    :visible="drawerShow"
    :closable="false"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <div>
      <a-form-model
        ref="ruleForm"
        :validateOnRuleChange="true"
        :model="form"
        :rules="formRules"
        :form="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item label="部门">
          {{form.deptName}}
        </a-form-model-item>
        <a-form-model-item label="职位">
          {{form.deptPositionName}}
        </a-form-model-item>
        <a-form-model-item label="工程师">
          {{form.username}}
        </a-form-model-item>
        <!-- <a-form-model-item label="早餐补贴">
          {{form.breakfastFee}}
        </a-form-model-item>
        <a-form-model-item label="午餐补贴">
          {{form.lunchFee}}
        </a-form-model-item>
        <a-form-model-item label="晚餐补贴">
          {{form.supperFee}}
        </a-form-model-item>
        <a-form-model-item label="住宿补贴" prop="hotelFee">
          <a-input placeholder="输入住宿补贴" v-model="form.hotelFee" style="width:150px;margin-right: 5px;" :disabled="!aloneChecked" />
          <a-checkbox v-model="aloneChecked">单独设置</a-checkbox>
        </a-form-model-item> -->
        <a-form-model-item label="餐补" prop="mealFee">
          {{form.mealFee}}
        </a-form-model-item>
        <a-form-model-item label="交通费用" prop="carFee">
          <a-input placeholder="输入交通费用" v-model="form.carFee" />
        </a-form-model-item>
        <a-form-model-item label="特殊补贴" prop="specialFee">
          <a-input placeholder="输入特殊补贴" v-model="form.specialFee" />
        </a-form-model-item>
        <a-form-model-item label="其他费用" prop="otherFee">
          <a-input placeholder="输入其他费用" v-model="form.otherFee" />
        </a-form-model-item>
        <a-form-model-item label="差旅补贴" prop="workFee">
          <!-- {{form.workFee}} -->
          {{form.mealFee * 1 +
form.carFee * 1 +
form.specialFee * 1 +
form.otherFee * 1}}
        </a-form-model-item>
        <a-form-model-item label="起止时间" prop="rangeTime">
          <a-range-picker show-time  v-model="form.rangeTime"></a-range-picker>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="drawer-bootom-button">
			<a-button @click="handleClose()" style="margin-right: 0.8rem">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :loading="loading">编辑</a-button>
    </div>
  </a-drawer>
</template>
<script>
import moment from "moment";

const formRules = {
  hotelFee: [{ required: true, message: "请输入住宿补贴", trigger: "blur" }],
  carFee: [{ required: true, message: "请输入交通费用", trigger: "blur" }],
  specialFee: [{ required: true, message: "请输入特殊补贴", trigger: "blur" }],
  otherFee: [{ required: true, message: "请输入其他费用", trigger: "blur" }],
  rangeTime: [{ required: true, message: "请选择起止时间", trigger: "blur" }],
};
export default {
  name: "allowanceConfirmEdit",
  components: {},
  data() {
    return {
      form: {
        skillId: undefined,
        title: undefined,
        hotelFee: 0,
        carFee: 0,
        specialFee: 0,
        otherFee: 0,
        hotelAlone: 0,
        rangeTime: []
      },
      aloneChecked: false,
      loading: false,
      formRules,
      drawerShow: false,
    };
  },
  props: {
  },
  mounted() {
  },
  methods: {
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
    },
    handleClose() {
      this.drawerShow = false;
      this.loading = false;
      this.reset();
    },
    setForm(data) {
      for (let key in data) {
        this.form[key] = data[key];
      }
      this.aloneChecked = this.form.hotelAlone == 1 ? true : false;
      this.form.rangeTime = [moment(this.form.beginDate + ' ' + this.form.beginHour, "YYYY-MM-DD HH"), moment(this.form.endDate + ' ' + this.form.endHour, "YYYY-MM-DD HH")],
      this.drawerShow = true;
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.form.beginDate = moment(this.form.rangeTime[0]).valueOf();
          this.form.endDate = moment(this.form.rangeTime[1]).valueOf();
          this.form.beginHour = moment(this.form.rangeTime[0]).hour();
          this.form.endHour = moment(this.form.rangeTime[1]).hour();
          this.form.hotelAlone = this.aloneChecked ? 1 : 0;

          let allowance = JSON.parse(JSON.stringify(this.form));
          this.$put("allowance", { ...allowance }).then((r) => {
          this.$message.success(r.data.message);
          this.handleClose();
          this.$emit("success");
        });
        }
      });
    },
  },
};
</script>